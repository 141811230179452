/** @jsx jsx */
import { jsx, Box, Flex, Text, Heading } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoText = ({ children, sx, ...rest }) => (
  <Text sx={{ fontSize: 1, ...sx }} {...rest}>
    {children}
  </Text>
)

const Info = ({ title, children }) => {
  return (
    <Flex
      sx={{
        bg: "highlight",
        mr: [3],
        my: [3],
        p: [3],
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Heading as="h4" sx={{ mb: 2 }}>
        {title}
      </Heading>
      {children}
    </Flex>
  )
}

const ChicagoOfficeInfo = ({ contactInfo }) => (
  <Info title="Office">
    <InfoText sx={{ fontStyle: "italic" }}>By appointment only</InfoText>
    <InfoText>{contactInfo.office_address}</InfoText>
    <InfoText sx={{ fontWeight: "bold", mt: 3 }}>
      {contactInfo.office_phone}
    </InfoText>
    <Flex my={2} sx={{ flexDirection: "column", alignItems: "center" }}>
      <InfoText sx={{ fontWeight: "bold", color: "red" }}>
        After hours emergencies
      </InfoText>
      <InfoText>{contactInfo.emergency_phone}</InfoText>
    </Flex>
    <Flex sx={{ flexDirection: "column", alignItems: "center", my: [3] }}>
      <InfoText>Mailing address</InfoText>
      <InfoText>{contactInfo.mailing_address}</InfoText>
    </Flex>
  </Info>
)

const LeasingInfo = ({ contactInfo }) => (
  <Info title="Leases and showings">
    <InfoText sx={{ fontWeight: "bold" }}>Chicago</InfoText>
    <InfoText>Cynthia Cotto, 773-281-5596</InfoText>
    <InfoText>leasing@sandmbuildings.com</InfoText>
    <InfoText sx={{ fontWeight: "bold", mt: [3] }}>
      Milwaukee, Shorewood, Racine
    </InfoText>
    <InfoText>{contactInfo.wi_contact}</InfoText>
    <InfoText>{contactInfo.wi_contact_email}</InfoText>
  </Info>
)

const ContactPage = ({ data }) => {
  const contactInfo = data.contactInfo.nodes[0]
  return (
    <Layout>
      <SEO title="Contact us" />
      <Box my={4}>
        <Text>
          For immediate information regarding leasing, please call our office or
          use the contact form below. Office hours are by appointment only.
        </Text>
        <br />
        <Text>
          We welcome any and all feedback, comments, or suggestions you may
          have. If you have an idea of how we can improve our service, please
          use the contact form below. Thank you for your business!
        </Text>
      </Box>
      <Flex
        sx={{
          justifyContent: "space-between",
          pr: [3],
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: "1" }}>
          <Heading as="h2" mb={4}>
            Additional contact information
          </Heading>
          <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
            <ChicagoOfficeInfo contactInfo={contactInfo} />
            <LeasingInfo contactInfo={contactInfo} />
          </Flex>
        </Box>
        <iframe
          title="Contact form"
          src="https://sandmbuildings.managebuilding.com/Resident/Public/Contact?hidenav=true"
          width="100%"
          height="100%"
          frameborder="0"
          style={{ display: "block", height: "100vh", width: "100%" }}
        />
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query {
    contactInfo: allStrapiContactInformation {
      nodes {
        mailing_address
        office_address
        office_phone
        emergency_phone
        wi_contact
        wi_contact_email
      }
    }
  }
`

export default ContactPage
